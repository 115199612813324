import React from "react";
import Policy from "../Policies";
import termsAndConditionsData from "./TermsAndConditionsData.json";
import SEO from '../../SEO';

const TermsAndConditions = () => {
  return (
  <>
    <SEO
    title="Terms of Service - Panostack"
    description="Read our current Terms of Service."
    keywords="create a tour, virtual tours, virtual reality, 360, photography"
    imageUrl="../../images/panostacklogo.png"
  />
  <Policy data={termsAndConditionsData} />;
  </>
  )
};

export default TermsAndConditions;
