import React from "react";
import { Grid } from "@mui/material";
import PostsSkeleton from "./PostsSkeleton";
import VisiblePosts from "./VisiblePosts";

const Posts = ({ setCurrentId, posts }) => {

  if (!posts) {
    return (
      <Grid
        container
        alignItems="stretch"
        spacing={3}
      >
        <PostsSkeleton />
      </Grid>
    );
  }

  return (
    <Grid
      container
      alignItems="stretch"
      spacing={3}
    >
      <VisiblePosts posts={posts} setCurrentId={setCurrentId} />
    </Grid>
  );
};

export default Posts;
