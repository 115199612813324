import React from "react";
import { DialogTitle, IconButton, Typography, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const FormModal = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle disableTypography {...other} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default FormModal;
