import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, imageUrl, url }) => {
  return (
    <Helmet>
      <title>{title || 'Panostack | Anytime. Anywhere.'}</title>
      <meta name="description" content={description || 'A platform for everything Virtual Reality, create tours, browse images, read articles, and more!'} />
      <meta name="keywords" content={keywords || 'VR, 360, technology'} />
      <meta property="og:title" content={title || 'Panostack'} />
      <meta property="og:description" content={description || 'A platform for everything Virtual Reality, create tours, browse images, read articles, and more!'} />
      <meta property="og:image" content={imageUrl || '../../images/panostacklogo.png'} />
      <meta property="og:url" content={url || window.location.href} />
      <link rel="canonical" href={url || window.location.href} />
    </Helmet>
  );
};

export default SEO;
