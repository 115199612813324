import React, { useState } from "react";
import { Card, CardHeader,  IconButton, Typography, CardMedia, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import VrpanoIcon from '@mui/icons-material/Vrpano';

const Post = ({ post, setCurrentId }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const openPost = () => {
    if (!anchorEl) {
      history.push(`/posts/${post._id}`);
    }
  };

    function truncateTags(tags, maxLength) {
      const tagString = tags.map(tag => `#${tag}`).join(' ');
      return tagString.length > maxLength ? `${tagString.substring(0, maxLength - 3)}...` : tagString;
    }
    


    const renderPostOptionIcon = () => {
      switch (post.option) {
        case "panorama":
          return  <IconButton aria-label="Panorama"><VrpanoIcon /></IconButton>;
        case "360image":
          return <IconButton aria-label="360"><ThreeDRotationIcon /></IconButton>;
        default:
          return null;
      }
    };  

  return (
    <Card raised elevation={6} onClick={openPost}>
    <CardHeader
      action={
        <Box>
            {renderPostOptionIcon()}
          </Box>
      }
      
      title={post.title.length > 16 ? `${post.title.substring(0, 16)}...` : post.title}
      subheader={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 1 }}>
          <Typography variant="caption" sx={{ marginRight: 2 }}> 
            {new Date(post.createdAt).toLocaleDateString()}
          </Typography>
          <Typography variant="caption">
            {truncateTags(post.tags, 20)}
          </Typography>
        </Box>
      }
    />
    <CardMedia
      component="img"
      height="194"
      image={post.store[0]?.url}
      alt={post.title}
    />
    </Card>
  );
};

export default Post;
