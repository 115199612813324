import React from 'react';
import { Link } from 'react-router-dom';
import { useFetchBlogsQuery } from "../../api";
import SEO from '../SEO';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Grid, Box, CircularProgress } from '@mui/material';

const Blogs = () => {
  const { data, error, isLoading } = useFetchBlogsQuery();

  if (isLoading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
      <Typography sx={{ ml: 2 }}>Loading Blogs...</Typography>
    </Box>
  );

  if (error) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography>Error: {error.message}</Typography>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 2, md: 4 }, backgroundColor: "#f9f9f9" }}>
      <SEO
        title="Explore Blogs - Panostack"
        description="Latest blogs on Panostack, covering a wide range of topics in the immersive technology industry, from innovative multimedia solutions to the latest in 360° technology."
        keywords="immersive technology, multimedia solutions, 360 technology, industry updates, technology blogs"
        imageUrl="../../images/panostacklogo.png"
      />
      <Typography variant="h1" component="h1" gutterBottom sx={{ textAlign: 'center', fontSize: { xs: '2.5rem', md: '3rem' }, fontWeight: 'bold', color: '#333', marginBottom: 4 }}>
        Explore Our Blogs
      </Typography>
      <Grid container spacing={3}>
        {data && data.results.map(blog => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={blog.id}>
            <Card sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
              }
            }}>
              <CardActionArea component={Link} to={`/blogs/${blog.uid}`}>
                {blog.data.coverimage.url && (
                  <CardMedia
                    component="img"
                    sx={{ height: 180, objectFit: 'cover' }}
                    image={blog.data.coverimage.url}
                    alt={blog.data.coverimage.alt || "Blog Cover"}
                  />
                )}
                <CardContent sx={{ padding: 2, flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333', fontSize: '1.2rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {blog.data.header[0].text}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      color: '#666',
                      fontSize: '0.95rem'
                    }}
                  >
                    {blog.data.description[0].text}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2, textAlign: 'right', borderTop: '1px solid #f0f0f0' }}>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(blog.data.createddate).toLocaleDateString()}
                  </Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Blogs;
