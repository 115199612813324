import React, { useState } from "react";
import "./FAQs.css";
import faqsData from "./FAQs.json";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const FAQs = () => {
  const [openIndices, setOpenIndices] = useState([]);

  const toggleAnswer = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <div className="faq_div" name="faq">
      <h1 className="div_heading">Frequently Asked Questions</h1>
      <div id="faq_list_div">
        {faqsData.map((faq, index) => (
          <div key={index} className="faq_item">
            <div className="faq_question" onClick={() => toggleAnswer(index)}>
              <div className="faq_question_text">{faq.question}</div>
              <div className="faq_icon">
                {openIndices.includes(index) ? <ExpandLess /> : <ExpandMore />}
              </div>
            </div>
            <div
              className={`faq_answer ${
                openIndices.includes(index) ? "show" : ""
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
