import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const UserMenu = ({
  user,
  anchorElUser,
  handleOpenUserMenu,
  handleCloseUserMenu,
  logout,
  userId,
}) => {
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={user?.result.name} src={user?.result.profilePicture}>
            {user?.result.name.charAt(0)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem divider>
          <Typography textAlign="center">{user?.result.name}</Typography>
        </MenuItem>
        {[
          { label: "My Profile", to: `/profile/${userId}` },
          { label: "Settings", to: `/settings/${userId}` },
          { label: "Logout", onClick: logout },
        ].map((item, index) => (
          <MenuItem
            key={index}
            sx={{ ":hover": { color: "#6495ED" } }}
            component={Link}
            to={item.to || "#"}
            onClick={item.onClick || handleCloseUserMenu}
          >
            <Typography textAlign="center">
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default UserMenu;
