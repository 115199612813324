import React from "react";
import { Avatar, Typography, Grid, Divider, Box } from "@mui/material";
import Form from "../Form/Form";

const NoPosts = ({ user, currentId, setCurrentId, classes }) => {
  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 4 }}>
        <Avatar
          alt="Your Name"
          src={user?.result.profilePicture}
          sx={{ width: 80, height: 80, marginBottom: 2 }}
        >
          {user?.result.name.charAt(0)}
        </Avatar>
        <Typography variant="h4" gutterBottom>
          {user?.result.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          @{user?.result.username}
        </Typography>
      </Box>
      <Typography variant="h5" gutterBottom>
            My Posts
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />
          <Grid item xs={12} sm={4} sx={{ marginBottom: 3 }}>
          <Form
            currentId={currentId}
            setCurrentId={setCurrentId}
          />
        </Grid>
      <Grid container>
      <Typography>You currently have no Posts.</Typography>
      </Grid>
    </Box>
  );
};

export default NoPosts;
