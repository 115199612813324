import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const ImgDropdownMenu = ({ store, selectedPano, handleSelectedPano }) => {
  const [isOpen, setIsOpen] = useState(false); // State to control dropdown visibility

  useEffect(() => {
    handleSelectedPano({ target: { value: selectedPano } });
  }, [handleSelectedPano, selectedPano]);

  const handleChange = (e) => {
    const index = parseInt(e.target.value, 10);
    handleSelectedPano({ target: { value: index } });
  };

  // Handle selecting an option
  const handleSelect = (index) => {
    handleSelectedPano({ target: { value: index } }); // Trigger the change
    setIsOpen(false); // Close the dropdown
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, paddingTop: 1, paddingBottom: 1, alignItems: "center" }}>
      <Typography sx={{ fontSize: '18px', color: 'white' }}>Select Hotspot Image</Typography>

      <div style={{ position: "relative", width: "100%", maxWidth: "300px" }}>
        {/* Selected Option Display (Like a Select Box) */}
        <div
          onClick={toggleDropdown}
          style={{
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            padding: "6px 12px",
            height: "30px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: "1px solid #ccc",
            fontSize: "14px", // Improve text size for better readability
            color: "#333", // Darken the text for better visibility
          }}
        >
          {selectedPano === "" ? (
    <p style={{ color: "#888" }}>Select an option</p>
  ) : (
    <span
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "calc(100% - 24px)", // Ensures space for the dropdown arrow
      }}
    >
      {store[selectedPano]?.panoName}
    </span>
  )}
  <span style={{ color: "#888" }}>▼</span> {/* Dropdown arrow */}
</div>

        {/* Dropdown Menu */}
        {isOpen && (
          <div
            style={{
              position: "absolute",
              top: "35px", // Position below the "select"
              left: 0,
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              maxHeight: "200px", // Set max height for scrolling
              overflowY: "auto",
              border: "1px solid #ccc", // Add border to make it stand out
            }}
          >
            {/* Empty Option */}
            <div
              style={{
                padding: "10px 12px",
                cursor: "pointer",
                backgroundColor: selectedPano === "" ? "#ddd" : "transparent",
                color: "#333", // Dark text for readability
                fontSize: "14px",
              }}
            >
              <em>Select an option</em>
            </div>

            {/* Map through store and render options */}
            {store.map((item, index) => (
              <div
                key={index}
                onClick={() => handleSelect(index)}
                style={{
                  padding: "10px 12px",
                  cursor: "pointer",
                  backgroundColor: selectedPano === index ? "#ddd" : "transparent",
                  color: "#333", // Dark text for better visibility
                  fontSize: "14px",
                  fontWeight: selectedPano === index ? "bold" : "normal", // Bold for the selected option
                  transition: "background-color 0.3s ease",
                  display: "block", 
                 whiteSpace: "nowrap",
                  overflow: "hidden", 
                 textOverflow: "ellipsis",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#e0e0e0")} 
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = selectedPano === index ? "#ddd" : "transparent")}
              >
                {item.panoName}
              </div>
            ))}
          </div>
        )}
      </div>
    </Box>
  );
};

export default ImgDropdownMenu;
