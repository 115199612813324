import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import Post from "./Post";

const VisiblePosts = ({ posts, setCurrentId }) => {
  const publicPosts = useMemo(() => {
    return posts.data.filter((post) => post.visibility === "post-public");
  }, [posts]);

  if (publicPosts.length === 0) {
    return (
      <div>
        <h1 style={{ fontSize: "3rem" }}>Hmmm...</h1>
        <br />
        <h2 style={{ fontSize: "2rem" }}>
          We're sorry. We were not able to find a match.
        </h2>
      </div>
    );
  }

  return (
    <>
      {publicPosts.map((post) => (
        <Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
          <Post post={post} setCurrentId={setCurrentId} />
        </Grid>
      ))}
    </>
  );
};

export default VisiblePosts;
