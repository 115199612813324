import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Button,
  InputAdornment,
  Dialog,
  Autocomplete,
  Chip
} from "@mui/material";
import FormModal from "./FormModal";
import PanoramaUpload from "../FileUpload/PanoramaUpload";
import MapUpload from "../FileUpload/MapUpload";

const FormContent = ({
  postData,
  handleSubmit,
  handleClickOpen,
  handleClose,
  open,
  post,
  currentId,
  clear,
  setPostData,
  maxFileNum,
  uploadText,
}) => {

  const handleTagsChange = (event, newValue) => {
    setPostData({ ...postData, tags: newValue });
  };

  const maxFileNumber = postData.postType === 'virtual-tour' ? 20 : 1;
  const uploadTextShow = postData.postType === 'virtual-tour' 
    ? 'Upload Panoramic Images' 
    : 'Upload Panoramic Image';

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        onClick={handleClickOpen}
      >
        {currentId ? `Edit "${post?.title}"` : "Create a Post"}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Paper elevation={6} sx={{ p: 3 }}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <FormModal id="customized-dialog-title" onClose={handleClose}>
              {currentId ? `Editing "${post?.title}"` : "Create a Post"}
            </FormModal>
            <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              required
              name="title"
              variant="outlined"
              label="Title"
              fullWidth
              value={postData.title}
              inputProps={{ maxLength: 40 }}
              onChange={(e) => setPostData({ ...postData, title: e.target.value })}
             
            />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="message"
              variant="outlined"
              label="Message"
              fullWidth
              multiline
              rows={4}
              value={postData.message}
              inputProps={{ maxLength: 450 }}
              onChange={(e) => setPostData({ ...postData, message: e.target.value })}
    
            />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              value={postData.tags || []}
              onChange={handleTagsChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tags (optional)"
                  placeholder="Add tags"
                  fullWidth
                />
              )}
       
            />
            </FormControl>
            {!currentId && (
             <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Post Type:</InputLabel>
              <Select
                required
                label="Select Post Type:"
                value={postData.postType || post?.postType}
                onChange={(e) => setPostData({ ...postData, postType: e.target.value })}
              >
                <MenuItem value="single-image">Single Image</MenuItem>
                <MenuItem value="virtual-tour">Virtual Tour</MenuItem>
              </Select>
            </FormControl>
             )}
              {postData.postType !== "single-image" && (
            <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="price"
              type="number"
              variant="outlined"
              label="Price (optional)"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">$</InputAdornment>,
              }}
              value={postData.price}
              onChange={(e) => setPostData({ ...postData, price: e.target.value })}
            />
            </FormControl>
             )}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Post Visibility:</InputLabel>
              <Select
                required
                label="Select Post Visibility:"
                value={postData.visibility || post?.visibility}
                onChange={(e) => setPostData({ ...postData, visibility: e.target.value })}
              >
                <MenuItem value="post-public">Public</MenuItem>
                <MenuItem value="post-private">Private</MenuItem>
              </Select>
            </FormControl>
            {!currentId && (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select File Type:</InputLabel>
                <Select
                  required
                  label="Select File Type:"
                  onChange={(e) => setPostData({ ...postData, option: e.target.value })}
                >
                  <MenuItem value="360image">360° Image</MenuItem>
                  <MenuItem value="panorama">Panorama</MenuItem>
                </Select>
                <PanoramaUpload postData={postData} setPostData={setPostData} maxFileNum={maxFileNumber} uploadText={uploadTextShow} />
              </FormControl>
            )}
             {postData.postType !== "single-image" && (
             <FormControl fullWidth sx={{ mb: 2 }}>
            <MapUpload postData={postData} setPostData={setPostData} />
            </FormControl>
             )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button onClick={clear} fullWidth>
              Clear
            </Button>
          </form>
        </Paper>
      </Dialog>
    </>
  );
};

export default FormContent;

