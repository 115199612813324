import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Cache the ref to avoid repeated calls
let cachedRef = null;

// Function to fetch the latest ref from Prismic
async function fetchLatestRef() {
  if (cachedRef) return cachedRef; // Return the cached ref if it's available

  const response = await fetch('https://panostackblogs.cdn.prismic.io/api/v2');
  const data = await response.json();
  cachedRef = data.refs.find((ref) => ref.isMasterRef).ref; // Assuming 'isMasterRef' marks the latest ref
  
  // You can set up a cache invalidation mechanism (e.g., timeout-based) if needed
  return cachedRef;
}

// Custom base query to handle fetching the latest ref and token
const customBaseQuery = async ({ url, method, data, params }) => {
  try {
    const ref = await fetchLatestRef();
    const baseUrl = 'https://panostackblogs.cdn.prismic.io/api/v2';
    const headers = new Headers();
    const prismicAccessToken = process.env.PRISMIC_ACCESS_TOKEN;

    if (prismicAccessToken) {
      headers.set('Authorization', `Token ${prismicAccessToken}`);
    }

    const finalUrl = `${baseUrl}${url}?ref=${ref}${params ? `&${new URLSearchParams(params)}` : ''}`;
    const response = await fetch(finalUrl, {
      method: method || 'GET',
      headers,
      body: data ? JSON.stringify(data) : undefined,
    });

    const responseData = await response.json();
    return { data: responseData };
  } catch (error) {
    return { error: { status: error.status, statusText: error.statusText } };
  }
};

export const prismicApi = createApi({
  reducerPath: 'prismicApi',
  baseQuery: customBaseQuery,
  tagTypes: ['Blogs'], 
  endpoints: (builder) => ({
    // Fetch blogs with field-specific queries to optimize performance
    fetchBlogs: builder.query({
      query: (page = 1) => ({
        url: '/documents/search',
        params: {
          q: `[[at(document.type, "blogs")]]`,
          fetch: 'my.blogs.title, my.blogs.description, my.blogs.coverimage, my.blogs.uid, my.blogs.createddate', // Fetch only necessary fields
          pageSize: 20,
          page, // Handle pagination by sending page number
        },
      }),
      providesTags: ['Blogs'],
      keepUnusedDataFor: 3600, // Cache for 1 hour to reduce repeated requests
      refetchOnMountOrArgChange: false, // Avoid refetching when remounting
    }),

    // Fetch blog by slug with a specific field list
    fetchBlogBySlug: builder.query({
      query: (slug) => ({
        url: '/documents/search',
        params: {
          q: `[[at(my.blogs.uid, "${slug}")]]`,
          fetch: 'my.blogs.title, my.blogs.description, my.blogs.content, my.blogs.coverimage, my.blogs.writtenby, my.blogs.writtenbyimage, my.blogs.createddate', // Fetch the required fields for blog detail page
        },
      }),
      providesTags: (result, error, slug) => [{ type: 'Blogs', id: slug }],
    }),
  }),
});

// Export the hooks to use the queries in your components
export const { useFetchBlogsQuery, useFetchBlogBySlugQuery } = prismicApi;
