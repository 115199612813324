import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Typography, CircularProgress, Grid, Divider } from "@mui/material";
import Post from "../Posts/Post/Post";
import { useGetPostsByCreatorQuery, useGetPostsBySearchQuery } from "../../api";
import SEO from "../../views/SEO";

const CreatorOrTag = () => {
  const { name } = useParams();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("profile"));
  const userId = user?.result.googleId || user?.result?._id;
  const [currentId, setCurrentId] = useState(0);

  const isTagRoute = location.pathname.startsWith("/tags");
  
  const { data: postsByCreator, isLoading: isLoadingByCreator } =
    useGetPostsByCreatorQuery(name, { skip: isTagRoute });
  const { data: postsByTag, isLoading: isLoadingByTag } =
    useGetPostsBySearchQuery({ tags: name }, { skip: !isTagRoute });

  const posts = isTagRoute ? postsByTag?.data : postsByCreator;
  const isLoading = isTagRoute ? isLoadingByTag : isLoadingByCreator;
console.log(posts)
  if (!posts?.length && !isLoading) return "No posts";

  return (
    <div>
      <SEO
        title={`${name} posts - Panostack`}
        description={`showing  posts with tag/name ${name} - Panostack`}
      />
      <Grid container justify="space-between">
        <Grid item>
          <Typography sx={{ fontSize: '3rem', marginBottom: 1 }} variant="h1">{name}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 3 }} />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          {posts?.map(
            (post) =>
              (post.visibility === "post-public" ||
                (post.visibility === "post-private" &&
                  post.creator === userId)) && (
                <Grid key={post._id} item xs={12} sm={12} md={6} lg={3}>
                  <Post post={post} setCurrentId={setCurrentId} />
                </Grid>
              )
          )}
        </Grid>
      )}
    </div>
  );
};

export default CreatorOrTag;
