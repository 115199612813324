import React from "react";
import {
  Pagination,
  PaginationItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";

const Paginate = ({ posts, isLoading, page }) => {
  const numberOfPages = posts?.numberOfPages;

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" my={2}>
        <CircularProgress />
      </Box>
    );
  return (
    <Box display="flex" justifyContent="center" my={2}>
      {" "}
      {/* MUI Box component with margin Y-axis */}
      <Pagination
        count={numberOfPages}
        page={Number(page) || 1}
        variant="outlined"
        color="primary"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component={Link}
            to={`/posts?page=${item.page}`}
            sx={{
              m: 0.5,
              ":hover": {
                color: "cornflowerblue",
              },
            }} // Add slight margin around PaginationItem for better spacing
          />
        )}
        sx={{
          padding: "10px", // Padding around Pagination for better interaction area
          display: "flex", // Ensure Pagination component is flexible
          justifyContent: "center", // Center Pagination items
        }}
      />
    </Box>
  );
};

export default Paginate;
