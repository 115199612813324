import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Html } from "@react-three/drei";

const Infospots = ({
  displaySpots,
  ifps,
  info,
  makeSpotActiveToEdit,
  activeSpotToEdit,
  editState,
}) => {
  const [visiblePop, setVisiblePop] = useState(null);

  const handleIconClick = (index) => {
    setVisiblePop(index);
  };

  const handleClose = () => {
    setVisiblePop(null);
  };

  return (
    <>
      {displaySpots &&
        ifps?.map((position, index) => (
          <mesh position={position} key={index}>
            <Html
              center
              className={`spot infospot ${
                editState.infospotActive && activeSpotToEdit === index
                  ? "active"
                  : ""
              }`}
              zIndexRange={[5, 0]}
            >
              <div className="pulse-border"></div>
              {visiblePop === index && (
  <div
    style={{
      position: "absolute",
      bottom: "110%", // Ensure it doesn't overlap the infospot
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "rgba(0, 0, 0, 0.85)", // Dark background for contrast
      color: "white",
      padding: "15px", // Extra padding for better spacing
      paddingTop: "25px", // Increased top padding for space between text and top edge
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      zIndex: 10,
      maxWidth: "300px", // Limit the width of the popup
      maxHeight: "200px", // Limit the height of the popup
      overflowY: "auto", // Enable vertical scrolling when content overflows
      textAlign: "center",
      whiteSpace: "pre-wrap", // Ensure text wraps and preserves line breaks
      wordWrap: "break-word", // Handle long words correctly
    }}
  >
    {/* Popup Text */}
    <p
      style={{
        margin: "0 0 10px 0", // Ensure there's no excessive space at the top
        lineHeight: "1.6", // Adjust for better readability
        fontSize: "14px", // Suitable text size
      }}
    >
      {info[index]}
    </p>

    {/* Close Button */}
    <button
      onClick={handleClose}
      style={{
        background: "none", 
        border: "none", 
        color: "black", 
        cursor: "pointer",
        padding: "8px 16px",
        fontSize: "14px", 
        fontWeight: "bold", 
        display: "inline-block",
        borderRadius: "4px",
        backgroundColor: "white", 
      }}
    >
      Close
    </button>
  </div>
)}
              <InfoIcon
                fontSize="large"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  makeSpotActiveToEdit(index);
                  handleIconClick(index);
                }}
              />
            </Html>
          </mesh>
        ))}
    </>
  );
};

export default Infospots;
