import React from "react";
import StatisticsCard from "./StatisticsCard";
import statisticsData from "./statisticsData.json";
import iconVirtualTour from "../../../images/panostacklogo.png";
import "./styles.css";

function StatisticsSection() {
  const { sectionTitle, statistics } = statisticsData;

  return (
    <div className="statisticsSectionContainer">
      <h1 className="div_heading">{sectionTitle}</h1>
      <div className="statisticsSection">
        {statistics.map((data, index) => (
          <StatisticsCard
            key={index}
            {...data}
            icon={iconVirtualTour}
            source={data.detailSource}
            url={data.url}
          />
        ))}
      </div>
    </div>
  );
}

export default StatisticsSection;
