import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile")).token
        : null;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
   tagTypes: ['User'], 
  endpoints: (builder) => ({
    updateUser: builder.mutation({
      query: ({ id, updatedUser }) => ({
        url: `/user/settings/${id}`,
        method: "PATCH",
        body: updatedUser,
      }),
       invalidatesTags: ['User'],
    }),
  }),
});

export const { useUpdateUserMutation } = usersApi;
