import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import { usersApi } from "./api/usersApi";
import { postsApi } from "./api/postsApi";
import { authApi } from "./api/authApi";
import { panoramaApi } from "./api/panoramaApi";
import { uploadApi } from "./api/uploadApi";
import { prismicApi } from "./api/prismicApi";
import App from "./App";
import "./index.css";

const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [panoramaApi.reducerPath]: panoramaApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [prismicApi.reducerPath]: prismicApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(usersApi.middleware)
      .concat(postsApi.middleware)
      .concat(authApi.middleware)
      .concat(panoramaApi.middleware)
      .concat(uploadApi.middleware)
      .concat(prismicApi.middleware)
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
