import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import Button from "@mui/material/Button";
import Icon from "./icon";
import { useSignInMutation, useSignUpMutation } from "../../../api";
import toast from "react-hot-toast";

const GoogleLoginButton = () => {
  const history = useHistory();
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const [
    signIn,
    {
      isLoading: isSigningIn,
      error: signInError,
      isSuccess: signInSuccess,
      data: signInData,
    },
  ] = useSignInMutation();
  const [
    signUp,
    {
      isLoading: isSigningUp,
      error: signUpError,
      isSuccess: signUpSuccess,
      data: signUpData,
    },
  ] = useSignUpMutation();

  useEffect(() => {
    if (signInSuccess) {
      // Assuming the returned data contains the user profile
      localStorage.setItem("profile", JSON.stringify(signInData));
    }
  }, [signInSuccess, signInData]);

  useEffect(() => {
    if (signUpSuccess) {
      // Assuming the returned data contains the user profile
      localStorage.setItem("profile", JSON.stringify(signUpData));
    }
  }, [signUpSuccess, signUpData]);

  const handleGoogleLoginSuccess = async (response) => {
    try {
      // Extract user data from the response object
      const {
        profileObj: { email, givenName, familyName },
      } = response;

      const googleUser = {
        email,
        username: email,
        firstName: givenName,
        lastName: familyName,
        isGoogleLogin: true, // Add the isGoogleLogin flag to indicate Google login
      };

      try {
        // Try to sign up the user
        const signUpResult = await signUp(googleUser).unwrap();
        toast.success("Successfully signed up!");
        history.push("/posts");
      } catch (error) {
        // If sign-up fails because the user already exists, try to sign them in
        if (error.data?.message === "User already exists") {
          try {
            const signInResult = await signIn({
              email,
              isGoogleLogin: true,
            }).unwrap();
            toast.success("Successfully signed in!");
            history.push("/posts");
          } catch (signUpError) {
            toast.error(signInError.data?.message || "Failed to sign Up.");
          }
        } else {
          toast.error(error.data?.message || "Failed to sign up.");
        }
      }
    } catch (error) {
      console.error("Error handling Google login:", error);
      toast.error("Error handling Google login. Please try again later.");
    }
  };

  const googleError = () => {
    console.log("Google Sign In was unsuccessful. Try again later");
    toast.error("Google Sign In was unsuccessful. Try again later");
  };

  return (
    <GoogleLogin
      clientId={googleClientId}
      render={(renderProps) => (
        <Button
          color="primary"
          fullWidth
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          startIcon={<Icon />}
          variant="contained"
        >
          Sign in with Google
        </Button>
      )}
      onSuccess={handleGoogleLoginSuccess}
      onFailure={googleError}
      cookiePolicy="single_host_origin"
    />
  );
};

export default GoogleLoginButton;
