import React from "react";
import { Container, Grid, Typography, Link, Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const footerLinkStyle = {
  display: "block",
  textDecoration: "none",
  marginBottom: "0.5rem",
  ":hover": {
    textDecoration: "underline", // Underline on hover
    color: "cornflowerblue", // Text color on hover
  },
};

const Footer = () => {
  return (
    <Box component="footer" mt={8} sx={{ bgcolor: "background.paper", py: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2">Email: panostack@gmail.com</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Policies
            </Typography>
            <Typography>
              <Link href="/privacy-policy" color="inherit" sx={footerLinkStyle}>
                Privacy Policy
              </Link>
              <Link
                href="/terms-conditions"
                color="inherit"
                sx={footerLinkStyle}
              >
                Terms of Service
              </Link>
              <Link href="/cookie-policy" color="inherit" sx={footerLinkStyle}>
                Cookie Policy
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Link
                href="#"
                color="inherit"
                sx={{ ":hover": { color: "#6495ED" } }}
              >
                <FacebookIcon />
              </Link>
              <Link
                href="https://www.instagram.com/panostack/?hl=en"
                color="inherit"
                sx={{ ":hover": { color: "#6495ED" } }}
              >
                <InstagramIcon />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
