import { useLoader } from "@react-three/fiber";
import * as THREE from "three";

export function useMapsLoader(store) {
  return useLoader(
    THREE.TextureLoader,
    store.map((entry) => {
      if (entry.url.substring(0, 5) === "https") {
        return entry.url;
      } else if (entry.url.substring(0, 10) === "data:image") {
        return entry.url;
      } else if (entry.url.substring(0, 1) === "/") {
        return entry.url;
      } else {
        console.error("ERROR: Something is wrong with the URLs");
        return null;
      }
    })
  );
}
