import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile")).token
        : null;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        console.log("No token found in localStorage.");
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (formData) => ({
        url: "/auth/signin",
        method: "POST",
        body: formData,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem("profile", JSON.stringify(data));
          const token = localStorage.getItem("profile")
            ? JSON.parse(localStorage.getItem("profile")).token
            : null;
        } catch (error) {}
      },
    }),
    signUp: builder.mutation({
      query: (formData) => ({
        url: "/auth/signup",
        method: "POST",
        body: formData,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem("profile", JSON.stringify(data));
        } catch (error) {}
      },
    }),
    sendResetPasswordLink: builder.mutation({
      query: (email) => ({
        url: "/auth/forgot",
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ password, token }) => ({
        url: `/auth/reset/${token}`,
        method: "POST",
        body: { password },
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useSendResetPasswordLinkMutation,
  useResetPasswordMutation,
} = authApi;
