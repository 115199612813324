import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useGetPostQuery, useDeletePostMutation, useUpdatePostMutation } from "../../api";
import { Paper, Divider, Button, Box, IconButton, Menu, MenuItem, Dialog, CircularProgress } from "@mui/material";
import CommentSection from "../../components/PostDetails/CommentSection";
import EditIcon from "@mui/icons-material/Edit";
import "./EditButton.css";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PostHeader from "../../components/PostDetails/PostHeader";
import PostContent from "../../components/PostDetails/PostContent";
import FormContent from "../../components/Form/FormContent";
import { Popconfirm } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast"; 
import { pink } from "@mui/material/colors";
import SEO from '../SEO';


const PostDetails = () => {

  const user = JSON.parse(localStorage.getItem("profile"));
  const { id } = useParams();
  const history = useHistory();
  const [
    updatePost,
    { isLoading: updatingPost, isSuccess: updatePostSuccess },
  ] = useUpdatePostMutation();

  const {
    data: post,
    isLoading: postLoading,
    isError: postError,
  } = useGetPostQuery(id);
  const isCurrentUser = user?.result?.googleId === post?.creator || user?.result?._id === post?.creator;
  const [deletePost, { isLoading }] = useDeletePostMutation();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [postData, setPostData] = useState({
    title: "",
    message: "",
    tags: [],
    price: "",
    option: "",
    panoName: "",
    selectedFile: [],
    visibility: "",
  });

  const resetPostData = () => {
    setPostData({ ...post });
  };

  useEffect(() => {
    if (post) {
      setPostData({
        ...post,
      });
    }
  }, [post]);

  var defaultTour = [
    {
      name: [],
      infospotInfo: [],
      color: "lightpink",
      position: [],
      infospot: [],
      panoName: "1.JPG",
      randPanoName: "akdjvnshfirlajsilfrd-1.JPG",
      url: "/1.JPG",
      link: [],
    },
  ];

  const [store, setStore] = useState(defaultTour);

  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  useEffect(() => {
    if (post) {
      const clonedStore = deepClone(post.store);
      setStore(clonedStore);
    }
  }, [post]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setOpen(true);
    handleMenuClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const postInfo = { ...postData, name: user?.result?.name};

    const action = updatePost;
    const payload = { id: post._id, updatedPost: postInfo };

    toast
      .promise(action(payload).unwrap(), {
        loading: "Processing the post...",
        success:
         "Post updated successfully!",
        error:
          "Failed to update post.",
      })
      .then((response) => {
        history.push(`/posts/${post._id}`);
      })
      .catch((error) => {
          toast.error(`${error.data.message}`)
      });
  };

  const handleDelete = async () => {
  toast
    .promise(deletePost(post._id).unwrap(), {
      loading: "Deleting the post...",
      success: "Post deleted successfully!",
      error: "Failed to delete post.",
    })
    .then((response) => {
      history.push("/posts");
    })
    .catch((error) => {
      toast.error(`${error.data.message}`);
    });
};

if (!post) {
  return (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
  );
}

  return (
    <>
        <SEO
        title={`${post.title} - Panostack Tours`}
        description={post.message}
        imageUrl={post.store[0]?.url}
      />
    <Paper style={{ padding: "20px", borderRadius: "15px" }} elevation={6}>
       {isCurrentUser && (
               <Box sx={{ marginBottom: "-45px" }} >
               <IconButton aria-label="settings" onClick={handleMenuOpen}>
                 <MoreVertIcon fontSize="large"/>
               </IconButton>
               <Menu
                 anchorEl={anchorEl}
                 open={Boolean(anchorEl)}
                 onClose={handleMenuClose}
                 onClick={(e) => e.stopPropagation()} // Prevent openPost from triggering when clicking inside the Menu
               >
                 <MenuItem onClick={handleEdit}>
                 <Button size="small">
                   <EditIcon sx={{ marginRight: "5px" }}  fontSize="small" />   Edit Post
                   </Button>
                 </MenuItem>
                 <MenuItem onClick={handleDelete}>
                     <Button size="small" sx={{ color: pink[500] }}>
                       <DeleteIcon sx={{ color: pink[500], marginRight: "5px" }} fontSize="small" />
                       Delete Post
                     </Button>
                 </MenuItem>
               </Menu>
             </Box>
      )}
          <PostHeader post={post} />
          <PostContent post={post} store={store} setStore={setStore} id={id} />
          <div>
               <Divider style={{ margin: "40px 0" }} />
            <CommentSection postId={post._id} />
            <Divider style={{ margin: "20px 0" }} />
          </div>
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <FormContent
        postData={postData}
        handleSubmit={handleSubmit}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        post={post}
        currentId={post?._id}
        clear={resetPostData}
        setPostData={setPostData}
      />
      </Dialog>
    </Paper>
    </>
  );
};

export default PostDetails;
