import React from "react";
import {
  Container,
  Grow,
  Grid,
  AppBar,
  TextField,
  Button,
  Autocomplete,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import Posts from "../Posts/Posts";
import Form from "../Form/";
import Pagination from "./Pagination";

const HomeContent = ({
  currentId,
  setCurrentId,
  search,
  setSearch,
  tags,
  setTags,
  searchPost,
  handleKeyPress,
  searchQuery,
  page,
  posts,
  isLoading,
  type, 
  setType, 
}) => {
  return (
    <Grow in>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
          <Grid item xs={12} sm={12} md={9} order={{ xs: 2, md: 1 }}>
            <Posts posts={posts} setCurrentId={setCurrentId} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} order={{ xs: 1, md: 2 }} sx={{ p: 2 }}>
            <AppBar position="static" color="inherit" sx={{ padding: '20px'}}>
              <TextField
                onKeyDown={handleKeyPress}
                name="search"
                variant="outlined"
                label="Search Posts"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <Autocomplete
                multiple
                id="tags-search"
                options={[]}
                freeSolo
                value={tags}
                onChange={(event, newValue) => setTags(newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search Tags"
                    placeholder="Add tags"
                    fullWidth
                  />
                )}
                sx={{ mb: 2 }}
              />
               <FormControl component="fieldset">
                <FormLabel component="legend" id="demo-row-radio-buttons-group-label">Filter by Type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <FormControlLabel value="single-image" control={<Radio />} label="Images" />
                  <FormControlLabel value="virtual-tour" control={<Radio />} label="Virtual Tours" />
                  <FormControlLabel value="" control={<Radio />} label="All" />
                </RadioGroup>
              </FormControl>
              <Button
                onClick={searchPost}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mb: 3 }}
              >
                Search
              </Button>
              <Form posts={posts} currentId={currentId} setCurrentId={setCurrentId} />
            </AppBar>
            {!searchQuery && !tags.length && (
              <Pagination 
                isLoading={isLoading} 
                posts={posts} 
                page={page}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Grow>
  );
};

export default HomeContent;

