import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCreatePostMutation, useUpdatePostMutation } from "../../api";
import AuthenticateMessage from "./AuthenticateMessage";
import FormContent from "./FormContent";
import toast from "react-hot-toast";

const Form = ({ posts, currentId, setCurrentId }) => {
  const [postData, setPostData] = useState({
    title: "",
    message: "",
    tags: [],
    price: "",
    option: "",
    map: "",
    panoName: "",
    selectedFile: [],
    visibility: "",
    postType: "",
  });

  const post = posts?.data.find((post) => post._id === currentId) || null;
  const [
    createPost,
    { isLoading: creatingPost, isSuccess: createPostSuccess },
  ] = useCreatePostMutation();
  const [
    updatePost,
    { isLoading: updatingPost, isSuccess: updatePostSuccess },
  ] = useUpdatePostMutation();

  const [open, setOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const userId = user?.result?._id;
  const history = useHistory();

  const clear = () => {
    setCurrentId(0);
    setPostData({
      title: "",
      message: "",
      tags: [],
      price: "",
      option: "",
      panoName: "",
      selectedFile: "",
      startIndex: 0,
      visibility: "",
      postType: "",
    });
  };
  useEffect(() => {
    if (currentId && posts) {
      const postToEdit = posts.data.find((post) => post._id === currentId);
      if (postToEdit) {
        setPostData({
          ...postToEdit,
        });
      }
    }
  }, [currentId, posts]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (postData.selectedFile === "") {
      toast.error("Please upload your 360° image");
      return;
    }

    const postInfo = { ...postData, name: user?.result?.name, creator: userId };

    const action = currentId === 0 ? createPost : updatePost;
    const payload =
      currentId === 0 ? postInfo : { id: currentId, updatedPost: postInfo };

    toast
      .promise(action(payload).unwrap(), {
        loading: "Processing the post...",
        success:
          currentId === 0
            ? "Post created successfully!"
            : "Post updated successfully!",
        error:
          currentId === 0 ? "Failed to create post." : "Failed to update post.",
      })
      .then((response) => {
        history.push(`/posts/${currentId === 0 ? response._id : currentId}`);
      })
      .catch((error) => {
          toast.error(`${error.data.message}`)
      });
  };

  const handleAddChip = (tag) => {
    setPostData({ ...postData, tags: [...postData.tags, tag] });
  };

  const handleDeleteChip = (chipToDelete) => {
    setPostData({
      ...postData,
      tags: postData.tags.filter((tag) => tag !== chipToDelete),
    });
  };

  const handleTagsChange = (event, newValue) => {
    setPostData({ ...postData, tags: newValue });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!user?.result?.name) {
    return <AuthenticateMessage />;
  }

  return (
    <FormContent
      postData={postData}
      handleSubmit={handleSubmit}
      handleAddChip={handleAddChip}
      handleDeleteChip={handleDeleteChip}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      open={open}
      post={post}
      currentId={currentId}
      clear={clear}
      setPostData={setPostData}
    />
  );
};

export default Form;
