import React from "react";
import { Typography, Box, Container, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import aboutUsData from "./aboutUsData.json";
import SEO from '../SEO';

const AboutUs = () => {
  const { welcome, createTours, ourTeam, thankYou } = aboutUsData;


  return (
    <Container maxWidth="md">
        <SEO
        title="About Us - Panostack"
        description="
        Discover immersive VR experiences with [Your Company Name]. Explore our platform dedicated to 360-degree images and virtual tours. Create, share, and enjoy captivating virtual reality content. Learn more about our innovative solutions on our About Us page. Join the VR revolution today!"
        keywords="about, informative, story, vr, 360, photography"
        imageUrl="../../images/panostacklogo.png"
      />
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h1" component="h1" color="primary" gutterBottom sx={{ fontSize: '2.5rem'}}>
          {welcome.title}
        </Typography>
        <Typography variant="h4" color="primary" gutterBottom sx={{ fontSize: '1.75rem' }}>
          {welcome.subtitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {welcome.content}
        </Typography>
        
        <Typography variant="h2" color="primary" gutterBottom sx={{ fontSize: '2rem' }}>
          {createTours.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {createTours.content}
        </Typography>
        
        <Typography variant="h2" color="primary" gutterBottom sx={{ fontSize: '2rem' }}>
          {ourTeam.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {ourTeam.content}
        </Typography>
        
        <Typography variant="h2" color="primary" gutterBottom sx={{ fontSize: '2rem' }}>
          {thankYou.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {thankYou.content} <Link component={RouterLink} to="/auth" color="secondary">Sign up</Link> for Panostack today
          and start exploring our user-friendly platform. With our affordable
          pricing plans and amazing features, you can create stunning virtual
          tours that will take your business to the next level.
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUs;

