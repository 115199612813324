import React from "react";
import { Grid, Card, CardHeader, Box, CardContent, Skeleton } from "@mui/material";

const PostsSkeleton = () => {

  return Array.from({ length: 8 }).map((_, index) => (
    <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
               <Card  raised elevation={6} sx={{ maxWidth: 345, margin: 0, overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, m: 0 }}>
        <Box sx={{ flex: 1 }}>
          <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="50%" />
        </Box>
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </Box>
      <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
        <Skeleton sx={{ height: 190, width: '100%' }} animation="wave" variant="rectangular" />
      </CardContent>
    </Card>
    </Grid>
  ));
};

export default PostsSkeleton;
