import React from "react";
import { Html } from "@react-three/drei";

const Hotspots = ({
  displaySpots,
  hps,
  store,
  setWhich,
  hotspotArray,
  name,
  link,
  makeSpotActiveToEdit,
  activeSpotToEdit,
  editState,
}) => {
  return (
    <>
      {displaySpots &&
        hps?.map((position, index) => (
          <mesh position={position} key={index}>
            <Html center className="hotspot-wrapper" zIndexRange={[5, 0]}>
              <div onClick={() => makeSpotActiveToEdit(index)}>
                {document.getElementsByClassName("hotspot-active").length >
                0 ? (
                  store[hotspotArray[index]] && (
                    <div
                      className={`spot hotspot ${
                        editState.hotspotActive && activeSpotToEdit === index
                          ? "active"
                          : ""
                      }`}
                      style={{
                        backgroundImage:
                          "url(" + store[hotspotArray[index]].url + ")",
                      }}
                    >
                      <div className="pulse-border"></div>
                      <p
                          style={{
                            color: "white",
                            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                            borderRadius: "15px",
                            wordWrap: "break-word",
                            textAlign: "center",
                            fontFamily:
                              "'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif",
                          }}
                        >
                          {name[index]}
                        </p>
                    </div>
                  )
                ) : (
                  <div
                    onClick={() => setWhich(link[index])}
                    className={`spot hotspot ${
                      activeSpotToEdit === index ? "active" : ""
                    }`}
                    style={{
                      backgroundImage:
                        store[hotspotArray[index]] &&
                        store[hotspotArray[index]].url
                          ? "url(" + store[hotspotArray[index]].url + ")"
                          : "",
                    }}
                  >
                     <p
                          style={{
                            color: "white",
                            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                            borderRadius: "15px",
                            wordWrap: "break-word",
                            textAlign: "center",
                            fontFamily:
                              "'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif",
                          }}
                        >
                          {name[index]}
                        </p>
                  </div>
                )}
              </div>
            </Html>
          </mesh>
        ))}
    </>
  );
};

export default Hotspots;
