import React from "react";
import {
  Avatar,
  Typography,
  Grid,
  Divider,
  LinearProgress,
  Box,
} from "@mui/material";
import Form from "../Form/Form";
import Post from "../Posts/Post/Post";

const ProfilePosts = ({ user, currentId, setCurrentId, posts, isLoading }) => {
  return (
    <Box sx={{ padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <Avatar
          alt="Your Name"
          src={user?.result.profilePicture}
          sx={{ width: 80, height: 80, marginBottom: 2 }}
        >
          {user?.result.name.charAt(0)}
        </Avatar>
        <Typography variant="h4" gutterBottom>
          {user?.result.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          @{user?.result.username}
        </Typography>
      </Box>
      <Typography variant="h5" gutterBottom>
        My Posts
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <Grid item xs={12} sm={4} sx={{ marginBottom: 3 }}>
        <Form posts={posts} currentId={currentId} setCurrentId={setCurrentId} />
      </Grid>
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1200,
            backgroundColor: "background.paper",
          }}
        >
          <LinearProgress sx={{ width: "100%" }} />
        </Box>
      )}
      <Grid container spacing={4} sx={{ marginTop: isLoading ? "4rem" : "0" }}>
        <Grid item xs={12} sm={8}>
          {!isLoading && (
            <Grid container spacing={3}>
              {posts?.data.map(
                (post) =>
                  (post.visibility === "post-public" ||
                    (post.visibility === "post-private" &&
                      post.creator === user?.result?._id)) && (
                    <Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
                      <Post post={post} setCurrentId={setCurrentId} />
                    </Grid>
                  )
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePosts;
