import React, { Component } from "react";
import "./ImgSlideShow.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class ImgSlideShow extends Component {
  handleClick = (index) => {
    this.props.setWhich(index);
  };

  state = {
    showImages: false,
  };

  handleArrowClick = () => {
    this.setState((prevState) => ({
      showImages: !prevState.showImages,
    }));
  };

  render() {
    const { store } = this.props;
    const { showImages } = this.state;

    return (
      <div className="carousel-container">
        {showImages && (
          <div className="carousel-items">
            {store.map((obj, index) => (
              <div
                key={index}
                className="carousel-item"
                onClick={() => this.handleClick(index)}
              >
                <img
                  src={obj.url}
                  alt={`Carousel ${index + 1}`}
                  className="carousel-image"
                  style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.6)" }}
                />
                <p
                  className="carousel-legend"
                  style={{ color: "white", textShadow: "2px 2px 4px black" }}
                >
                  {obj.panoName.length > 20
                    ? obj.panoName.substring(0, 20) + "..."
                    : obj.panoName}
                </p>
              </div>
            ))}
          </div>
        )}
        <div className="carousel-arrow">
          {showImages ? (
            <ExpandMoreIcon
              onClick={this.handleArrowClick}
              fontSize="large"
              style={{
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          ) : (
            <ExpandLessIcon
              onClick={this.handleArrowClick}
              fontSize="large"
              style={{
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ImgSlideShow;
