import React from "react";
import { Form, Field, ErrorMessage } from "formik";
import { Paper, Grid, Typography, Avatar, Button, TextField, Container, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import LockIcon from '@mui/icons-material/Lock';

function ResetForm({ formik }) {
  return (
    <Form>
      <Paper elevation={6} sx={{ p: 4, mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ mb: 2 }}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          Enter a New Password
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              as={TextField}
              id="password"
              name="password"
              type="password"
              label="Password"
              fullWidth
              variant="outlined"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={<ErrorMessage name="password" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              as={TextField}
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              label="Confirm Password"
              fullWidth
              variant="outlined"
              error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
              helperText={<ErrorMessage name="passwordConfirmation" />}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
        >
          Reset password
        </Button>
        {formik.isSubmitting && (
          <Alert severity="info" sx={{ mt: 2 }}>
            Resetting your password...
          </Alert>
        )}
        {formik.status && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {formik.status}
          </Alert>
        )}
      </Paper>
    </Form>
  );
}

export default ResetForm;

