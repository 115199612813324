import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import panostackLogo from "../../images/panostacklogo.png";

const NavLinksAndLogo = ({
  handleOpenNavMenu,
  handleCloseNavMenu,
  anchorElNav,
}) => {
  const navLinks = [
    { path: "/", label: "Home" },
    { path: "/about-us", label: "About Us" },
    { path: "/posts", label: "Create a Tour / Explore" },
    { path: "/blogs", label: "Blogs" },
  ];

  return (
    <>
      <Link to="/">
        <Avatar
          sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          alt="Panostack Logo"
          src={panostackLogo}
        />
      </Link>
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="/"
        sx={{
          mr: 2,
          display: { xs: "none", md: "flex" },
          fontFamily: "monospace",
          fontWeight: 600,
          letterSpacing: ".18rem",
          textDecoration: "none",
          ":hover": {
            color: "cornflowerblue",
          },
        }}
      >
        Panostack
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {navLinks.map((link) => (
          <Button
            key={link.path}
            component={Link}
            to={link.path}
            sx={{
              my: 2,
              display: "block",
              color: "inherit",
              ":hover": {
                color: "cornflowerblue",
              },
            }}
          >
            {link.label}
          </Button>
        ))}
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {navLinks.map((link) => (
            <MenuItem
              key={link.path}
              component={Link}
              to={link.path}
              onClick={handleCloseNavMenu}
              sx={{
                ":hover": {
                  color: "cornflowerblue",
                },
              }}
            >
              <Typography textAlign="center">{link.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Typography
        noWrap
        href=""
        sx={{
          mr: 2,
          display: { xs: "flex", md: "none" },
          flexGrow: 1,
        }}
      >
        <Link to="/">
          <Avatar
            sx={{ display: { xs: "flex", md: "none" } }}
            alt="Panostack Logo"
            src={panostackLogo}
          />
        </Link>
      </Typography>
    </>
  );
};

export default NavLinksAndLogo;
