import React, { useState } from "react";
import { Container, Typography, Paper, Button, Box } from "@mui/material";
import ProfilePictureUpload from "../../components/Settings/ProfilePicUploader";
import { useUpdateUserMutation } from "../../api";
import toast from "react-hot-toast";
import FormFields from "../../components/Settings/FormFields";
import SEO from '../SEO';

const Settings = () => {
  const [updateUser, { isSuccess, isError, error }] = useUpdateUserMutation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const userId = user?.result.googleId || user?.result?._id;

  const { name, username, _id: id, profilePicture, plan } = JSON.parse(localStorage.getItem("profile")).result;

  const placeholders = {
    name: name || "Current Name",
    username: username || "Current Username",
  };

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    confirmPassword: "",
    profilePicture: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      const updatedUserResponse = await updateUser({ id, updatedUser: formData }).unwrap();
      toast.success("Profile updated successfully");
      const currentProfile = JSON.parse(localStorage.getItem("profile"));
      localStorage.setItem("profile", JSON.stringify({
        ...currentProfile,
        result: { ...currentProfile.result, ...updatedUserResponse },
      }));
    } catch (err) {
      toast.error(err?.data?.message || "An error occurred");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePicture" && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData({ ...formData, profilePicture: reader.result });
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <SEO
        title="Account settings - Panostack"
        description="Change your account information."
        keywords="account, settings"
        imageUrl="../../images/panostacklogo.png"
      />
      <Paper elevation={6} sx={{ p: 3 }}>
        <Typography component="h1" variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
          Update Profile
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 2 }}>
            <FormFields
              formData={formData}
              handleInputChange={handleInputChange}
              placeholders={placeholders}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <ProfilePictureUpload
              handleInputChange={handleInputChange}
              formData={formData}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mb: 2 }}
          >
            Update
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Settings;

