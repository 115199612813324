import React from "react";

function ResetSuccessMessage() {
  return (
    <div className="container">
      <p>
        A reset link has been sent to your email.{" "}
        <b>You have 12 hours to activate your account. </b>
        It can take up to 15 min to receive our email.
      </p>
    </div>
  );
}

export default ResetSuccessMessage;
