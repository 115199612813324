import toast from "react-hot-toast";

export function addSpot(
  which,
  location,
  store,
  setStore,
  storeChangesLog,
  setStoreChangesLog
) {
  var temp;
  var lastIindex;

  var editEnabled = document.getElementsByClassName("hotspot-active");
  var infospotEnabled = document.getElementsByClassName("infospot-active");

  if (editEnabled.length > 0) {
    temp = [...store];
    lastIindex = temp[which].name.length - 1;

    if (
      temp[which].link[lastIindex] === "not-linked" &&
      temp[which].link[lastIindex] !== -1
    ) {
      temp[which].name.pop();
      temp[which].position.pop();
      temp[which].link.pop();
      setStore(temp);

      setStoreChangesLog((storeChangesLog) => storeChangesLog.slice(1));
    }

    temp[which].position.push([location.x, location.y, location.z]);
    temp[which].name.push("New Hotspot");
    temp[which].link.push("not-linked");
    setStore(temp);

    setStoreChangesLog((prevLog) => [
      {
        type: "hotspot",
        imageKey: which,
        hotspotKey: temp[which].name.length - 1,
      },
    ]);

    var lastLogIndex = storeChangesLog.length - 1;
    if (
      storeChangesLog.length - 1 > 0 &&
      storeChangesLog[lastLogIndex].imageKey ===
        storeChangesLog[lastLogIndex - 1].imageKey &&
      storeChangesLog[lastLogIndex].hotspotKey ===
        storeChangesLog[lastLogIndex - 1].hotspotKey
    ) {
      setStoreChangesLog((storeChangesLog) => storeChangesLog.slice(1));
    }
  }

  if (infospotEnabled.length > 0) {
    temp = [...store];
    lastIindex = temp[which].infospotInfo.length - 1;

    if (
      temp[which].infospotInfo[lastIindex] === "" &&
      temp[which].infospotInfo[lastIindex] !== -1
    ) {
      temp[which].infospotInfo.pop();
      temp[which].infospot.pop();
      setStore(temp);

      setStoreChangesLog((storeChangesLog) => storeChangesLog.slice(1));
    }
    console.log(temp[which]);
    temp[which].infospot.push([location.x, location.y, location.z]);
    temp[which].infospotInfo.push("");
    setStore(temp);

    setStoreChangesLog((prevLog) => [
      ...prevLog,
      {
        type: "infospot",
        imageKey: which,
        hotspotKey: temp[which].name.length - 1,
      },
    ]);
  }
}

export function useChangeTitle(store, setStore, setStoreChangesLog) {
  const changeTitle = (panoIndex, hotspotIndex, newTitle) => {
    if (newTitle.length > 15) {
      toast.error("Title cannot exceed 16 characters");
    }

    const temp = [...store];
    temp[panoIndex].name[hotspotIndex] = newTitle;

    setStore(temp);
    setStoreChangesLog((prevLog) => [
      ...prevLog,
      { type: "hotspot-title", imageKey: panoIndex, newTitle: newTitle },
    ]);
  };

  return changeTitle;
}

export function useChangeInfo(which, store, setStore, setStoreChangesLog) {
  const changeInfo = (panoIndex, infospotIndex, newInfo) => {
    console.log(which);
    const temp = [...store];

    temp[panoIndex].infospotInfo[infospotIndex] = newInfo;

    const hpKey = temp[which].name.length - 1;

    setStore(temp);
    setStoreChangesLog((prevLog) => [
      ...prevLog,
      { type: "infospot", imageKey: which, hotspotKey: hpKey },
    ]);
  };

  return changeInfo;
}
