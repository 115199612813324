import React from "react";
import "./Features.css";

const Showcase = ({ features }) => {
  return (
    <div id="features_div" name="features">
      <h1 className="div_heading">Features</h1>
      <div id="features_list_div">
        {features.map((item, i) => (
          <div key={i} className="features_item_div">
            <div className="features_image_div">
              <img className="svg" src={item.image} alt={item.heading} />
            </div>
            <div className="features_text_div">
              <h1>{item.heading}</h1>
              <p className="div_subheading left">{item.paragraph}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Showcase;
