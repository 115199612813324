import React from "react";
import { Formik, Form, Field } from "formik";
import { Paper, Grid, Button, Avatar, Container, Typography, Box, TextField } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from "react-router-dom";
import validationSchema from "./FormValidation";
import ResetSuccessMessage from "./ResetSuccessMessage";
import { useSendResetPasswordLinkMutation } from "../../../api";

function ForgotForm({ isSubmitted, setIsSubmitted }) {
  const [sendResetPasswordLink, { isLoading, isError, isSuccess, error }] = useSendResetPasswordLinkMutation();
  const initialValues = { email: "" };

  const onSubmit = (values, { setSubmitting }) => {
    sendResetPasswordLink(values.email)
      .unwrap()
      .then(() => setIsSubmitted(true))
      .catch((error) => {
        if (error.data) {
          console.log("Error submitting the form.")
        }
      })
      .finally(() => setSubmitting(false));
  };

  return isSubmitted ? (
    <ResetSuccessMessage />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Container component="main" maxWidth="xs">
          <Paper elevation={6} sx={{ p: 3, mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ mb: 2 }}>
              <EmailIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Request to reset password
            </Typography>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}  // Make sure to import TextField from @mui/material
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box mt={2} width="100%">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!formik.dirty || !formik.isValid}
                >
                  Send reset link
                </Button>
              </Box>
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Link to="/auth" style={{ textDecoration: 'none' }}>
                    Back to Login Page
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </Container>
      )}
    </Formik>
  );
}

export default ForgotForm;
