import React, { useState } from "react";
import Files from "react-files";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import toast from "react-hot-toast";

const PanoramaUpload = ({ postData, setPostData, maxFileNum, uploadText }) => {
  const [smth, setSmth] = useState();
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  function getBase64(file) {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }

  function onFilesChange(files) {
    setSmth(files[0]);

    const img = new Image();
    img.src = files[0].preview.url;
    img.onload = async () => {
      var w = img.width;
      var h = img.height;
      //used to be if w === 2 * h
      if (true) {
        var b64s = [];
        var imgNames = [];
        var filesizes = [];
        for (var i = 0; i < files.length; i++) {
          var base = await getBase64(files[i]);
          b64s.push(base);
          imgNames.push(files[i].name);
          filesizes.push(files[i].sizeReadable);
        }

        if (postData.selectedFile.length > 0) {
          setPostData({
            ...postData,
            panoName: [...postData.panoName, ...imgNames],
            selectedFile: [...postData.selectedFile, ...b64s],
            fileSize: [...postData.fileSize, ...filesizes],
          });
        } else {
          setPostData({
            ...postData,
            panoName: imgNames,
            selectedFile: b64s,
            fileSize: filesizes,
          });
        }
      } else {
        setPostData({ ...postData, panoName: "", selectedFile: "" });
        toast.error("Image must be a panorama, where width = 2 x height.");
      }
    };
  }

  function onFilesError(error, file) {
    console.log("error code " + error.code + ": " + error.message);
  }

  const handleStarClick = (index) => {
    setHighlightedIndex(index);
    setPostData({ ...postData, startIndex: index });
    toast.success("New Starting Image of Tour Selected");
  };

  return (
    <div className="files">
      <div>
        <Files
          className="files-dropzone"
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={[".JPG", ".JPEG", ".PNG", ".png"]}
          multiple
          maxFiles={maxFileNum}
          maxFileSize={100000000}
          minFileSize={0}
          clickable
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              cursor: "grab",
              padding: "15px",
              background: "lightgrey",
              margin: "5px 0px",
              borderRadius: "4px",
            }}
          >
            <div style={{ color: "grey" }}>{uploadText}</div>
          </div>
        </Files>
      </div>
      {postData.selectedFile && smth != null
        ? postData.selectedFile.map((selectedFile, index) => (
          <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '70vw',
            padding: '5px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            margin: '10px 0',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <IconButton
              aria-label="close"
              onClick={() => {
                if (highlightedIndex >= postData.panoName.length - 1) {
                  setHighlightedIndex(0);
                }
    
                const updatedImgNames = [
                  ...postData.panoName.slice(0, index),
                  ...postData.panoName.slice(index + 1),
                ];
                const updatedB64s = [
                  ...postData.selectedFile.slice(0, index),
                  ...postData.selectedFile.slice(index + 1),
                ];
                const updatedFileSizes = [
                  ...postData.fileSize.slice(0, index),
                  ...postData.fileSize.slice(index + 1),
                ];
    
                setPostData({
                  ...postData,
                  panoName: updatedImgNames,
                  selectedFile: updatedB64s,
                  fileSize: updatedFileSizes,
                });
              }}
              style={{ flexShrink: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <img
              style={{ width: '45vw', height: "70px", padding: '0 8px', flexShrink: 1 }}
              alt={selectedFile.name}
              src={selectedFile}
            />
            <IconButton
              onClick={() => handleStarClick(index)}
              style={{ flexShrink: 0 }}
            >
              {highlightedIndex === index ? (
                <StarIcon color="primary" />
              ) : (
                <StarBorderIcon />
              )}
            </IconButton>
          </div>
          <div style={{ textAlign: 'center', marginTop: '10px', overflow: 'hidden', width: '100%' }}>
            <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {postData.panoName[index].length > 20
                ? postData.panoName[index].substring(0, 20) + "..."
                : postData.panoName[index]}
            </p>
            <p style={{ margin: 0 }}>Size: {postData.fileSize[index]}</p>
          </div>
        </div>
          ))
        : null}
    </div>
  );
};

export default PanoramaUpload;
