import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetPostsQuery, useGetPostsBySearchQuery } from "../../api";
import HomeContent from "../../components/Home/HomeContent";
import SEO from '../SEO';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  const query = useQuery();
  const page = query.get("page") || 1;
  const searchQuery = query.get("searchQuery");
  const tagsQuery = query.get("tags");
  const typeQuery = query.get("type"); 
  const [currentId, setCurrentId] = useState(0);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const [type, setType] = useState(""); 
  const history = useHistory();
  const location = useLocation();

  const searchParams = {
    search: searchQuery || "none",
    tags: tagsQuery || "",
    type: typeQuery || "",
  };

  const {
    data: postsBySearch,
    isLoading: isLoadingSearch,
    isError: isErrorSearch,
  } = useGetPostsBySearchQuery(
    searchQuery || tagsQuery || typeQuery ? searchParams : undefined,
    {
      skip: !(searchQuery || tagsQuery || typeQuery),
    }
  );

  const {
    data: posts,
    isLoading: isLoadingPosts,
    isError,
  } = useGetPostsQuery(page);

  const searchPost = () => {
    if (search.trim() || tags.length > 0 || type) {
      history.push(
        `/posts/search?searchQuery=${search || "none"}&tags=${tags.join(",")}&type=${type}`
      );
    } else {
      history.push("/posts");
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };

  const finalPosts = searchQuery || tagsQuery || typeQuery ? postsBySearch : posts;
  const finalIsLoading =
    searchQuery || tagsQuery || typeQuery ? isLoadingSearch : isLoadingPosts;
  const finalIsError = searchQuery || tagsQuery || typeQuery ? isErrorSearch : isError;
  
  const handleAddChip = (tag) => setTags([...tags, tag]);
  const handleDeleteChip = (chipToDelete) =>
    setTags(tags.filter((tag) => tag !== chipToDelete));

  useEffect(() => {
    if (location.search === "") {
      setSearch("");
      setTags([]);
      setType("");
    }
  }, [location.search]);

  return (
    <>
      <SEO
        title="Create a Tour & Explore - Panostack"
        description="Create a Virtual Tour on our Free and Easy to use platform. Explore 360 photography, and share your own!"
        keywords="create a tour, virtual tours, virtual reality, 360, photography"
        imageUrl="../../images/panostacklogo.png"
      />
    <HomeContent
      posts={finalPosts}
      isLoading={finalIsLoading}
      isError={finalIsError}
      currentId={currentId}
      setCurrentId={setCurrentId}
      search={search}
      setSearch={setSearch}
      tags={tags}
      setTags={setTags}
      searchPost={searchPost}
      handleKeyPress={handleKeyPress}
      handleAddChip={handleAddChip}
      handleDeleteChip={handleDeleteChip}
      searchQuery={searchQuery}
      page={page}
      type={type}
      setType={setType}
    />
    </>
  );
};

export default Home;
