import React from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { useResetPasswordMutation } from "../../../api";
import validationSchema from "../../../components/Auth/Reset/validationSchema";
import { Container } from "@mui/material";
import ResetForm from "../../../components/Auth/Reset/ResetForm";

export default function Reset() {
  const { token } = useParams();

  const [resetPassword, { isLoading, isError, isSuccess, error }] =
    useResetPasswordMutation();

  const initialValues = {
    password: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    resetPassword({ password: values.password, token })
      .unwrap()
      .then(() => {})
      .catch((error) => {
        if (error.data) {
          console.log("error");
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Container component="main" maxWidth="xs">
          <ResetForm formik={formik} />
        </Container>
      )}
    </Formik>
  );
}
