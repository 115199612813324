import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <div id="hero-text" className="panorama">
        <h1 className="hero-title">Anytime. Anywhere.</h1>
        <h3 className="hero-subtitle">
          Create and share breathtaking 360 virtual tours with ease. Engage,
          inspire, and convert with Panostack.
        </h3>
        <div className="button-container">
          <Button
            component={Link}
            sx={{
              ":hover": {
                bgcolor: "#123456", // Background color on hover
                color: "#ffffff", // Text color on hover
              },
            }}
            to="/auth"
            variant="contained"
            color="primary"
          >
            Start Creating
          </Button>
          <Button
            component={Link}
            sx={{
              ":hover": {
                bgcolor: "#123456", // Background color on hover
                color: "#ffffff", // Text color on hover
              },
            }}
            to="/posts"
            variant="contained"
            color="primary"
          >
            Explore Tours
          </Button>
        </div>
      </div>
    </>
  );
};

export default Hero;
