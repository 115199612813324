import React, { useState } from "react";
import { useParams, useLocation, Redirect } from "react-router-dom";
import NoPosts from "../../components/MyProfile/NoPosts.js";
import ProfilePosts from "../../components/MyProfile/ProfilePosts";
import { useGetMyPostsQuery } from "../../api";

const MyProfile = () => {
  const { creator } = useParams();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("profile"));
  const userId = user?.result.googleId || user?.result?._id;

  const { data: posts, isLoading } = useGetMyPostsQuery(creator);

  const [currentId, setCurrentId] = useState(0);

  if (!location.pathname.startsWith(`/profile/${userId}`))
    return <Redirect to="/posts" />;

  if (!posts?.data.length && !isLoading)
    return (
      <NoPosts
        user={user}
        currentId={currentId}
        setCurrentId={setCurrentId}
      />
    );

  return (
    <ProfilePosts
      user={user}
      currentId={currentId}
      setCurrentId={setCurrentId}
      posts={posts}
      isLoading={isLoading}
    />
  );
};

export default MyProfile;
