import React from "react";

function StatisticsCard({ icon, title, percentage, source, url }) {
  return (
    <div className="statisticsCard">
      <img src={icon} alt={title} className="icon" />
      <p className="percentage">{percentage}</p>
      <h3>{title}</h3>
      {url ? (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="source"
        >
          {source}
        </a>
      ) : (
        <p className="source">{source}</p>
      )}
    </div>
  );
}

export default StatisticsCard;
