import React, { useState } from "react";
import ForgotForm from "../../../components/Auth/Forgot/ForgotForm";

export default function Forgot() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <ForgotForm isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
  );
}
