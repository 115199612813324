import React from "react";
import { Paper, Typography } from "@mui/material";

const AuthenticateMessage = () => {

  return (
    <Paper elevation={6}>
      <Typography variant="h6" p={2} align="center">
        Please Sign In to create your own posts and comment other's posts.
      </Typography>
    </Paper>
  );
};

export default AuthenticateMessage;
