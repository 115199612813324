import React from "react";
import Button from "@mui/material/Button";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const ProfilePictureUpload = ({ handleInputChange, formData }) => {
  return (
    <div>
      <input
        accept="image/*"
        id="profilePicture"
        name="profilePicture"
        type="file"
        onChange={handleInputChange}
        style={{ display: "none" }}
      />
      <label htmlFor="profilePicture">
        <Button
          color="primary"
          aria-label="upload picture"
          component="span"
          startIcon={<CameraAltIcon />}
        >
          Add or change your Profile Picture
        </Button>
      </label>
      {formData.profilePicture && (
        <img
          style={{ height: "100px", padding: "6px", borderRadius: "50%" }}
          alt="profilePicture"
          src={formData.profilePicture}
        />
      )}
    </div>
  );
};

export default ProfilePictureUpload;
