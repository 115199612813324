import React from "react";
import Button from "@mui/material/Button";
import MapIcon from '@mui/icons-material/Map';
import toast from 'react-hot-toast';

const MapUpload = ({ postData, setPostData }) => {

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handleInputChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected.");
      return;
    }

    const fileSizeLimit = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > fileSizeLimit) {
      toast.error("File size exceeds the 5MB limit. Please upload a smaller file.");
      return;
    }
    const base64 = await getBase64(file);

    setPostData({
      ...postData,
      map: base64,
    });
  };

  return (
    <div>
      <input
        accept="image/*"
        id="map"
        name="map"
        type="file"
        onChange={handleInputChange}
        style={{ display: "none" }}
      />
      <label htmlFor="map">
        <Button
          color="primary"
          aria-label="upload map / floor plan"
          component="span"
          startIcon={<MapIcon />}
        >
          Add Map / Floor Plan
        </Button>
      </label>
      {postData?.map && (
        <img
          style={{ maxHeight: "100px", maxWidth: "300px", padding: "12px" }}
          alt="Map Preview"
          src={postData.map}
        />
      )}
    </div>
  );
};

export default MapUpload;
