import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Policy = ({ data }) => {
  const { title, lastUpdated, sections } = data;

  const renderContent = (content) => {
    return content.split("\n\n").map((paragraph, index) => {
      const lines = paragraph.split("\n");
      if (lines.length > 1) {
        return (
          <ul key={index}>
            {lines.map((line, subIndex) => (
              <li key={subIndex}>{line}</li>
            ))}
          </ul>
        );
      } else {
        return <p key={index}>{paragraph}</p>;
      }
    });
  };

  return (
    <div>
      <Box sx={{ width: "90%" }}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="caption">Last updated: {lastUpdated}</Typography>
        <br />
        <br />
        {sections.map((section, index) => (
          <div key={index}>
            <Typography variant="h5">{section.title}</Typography>
            <br />
            <div>{renderContent(section.content)}</div>
            <br />
            <br />
          </div>
        ))}
      </Box>
    </div>
  );
};

export default Policy;
