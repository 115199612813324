import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Paper, Grid, Typography, Container, Box } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import Input from "./Input";
import GoogleLogin from "./GoogleLogin";

const SignUpForm = ({
  form,
  isSignup,
  showPassword,
  switchMode,
  setForm,
  handleSubmit,
  password,
  confirmPassword,
  handleShowPassword,
}) => {

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={6} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ margin: '20px 0' }}>
          {isSignup ? "Sign up" : "Sign in"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <Grid container spacing={2}>
            {isSignup && (
              <>
                <Input
                  name="firstName"
                  label="First Name"
                  handleChange={handleChange}
                  autoFocus
                  half
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  handleChange={handleChange}
                  half
                />
                <Input
                  name="username"
                  label="Username"
                  handleChange={handleChange}
                  fullWidth
                />
              </>
            )}
            <Input
              name="email"
              label="Email Address"
              handleChange={handleChange}
              type="email"
              fullWidth
            />
            <Input
              name="password"
              value={password}
              label="Password"
              handleChange={handleChange}
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
              fullWidth
            />
            {isSignup && (
              <Input
                name="confirmPassword"
                value={confirmPassword}
                label="Repeat Password"
                handleChange={handleChange}
                type={showPassword ? "text" : "password"}
                handleShowPassword={handleShowPassword}
                fullWidth
              />
            )}
          </Grid>
          <Grid container justifyContent="flex-end" sx={{ mt: 1 }}>
            <Link to="/auth/forgot" style={{ textDecoration: 'none' }}>
              Forgot Password?
            </Link>
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ my: 2 }}>
            {isSignup ? "Sign Up" : "Sign In"}
          </Button>
          <GoogleLogin sx={{ my: 2 }} />
          <Grid container justifyContent="flex-end">
            <Button onClick={switchMode} size="small">
              {isSignup ? "Already have an account? Sign in" : "Don't have an account? Sign Up"}
            </Button>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignUpForm;

