import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const panoramaApi = createApi({
  reducerPath: "panoramaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile")).token
        : null;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    deleteHotspot: builder.mutation({
      query: ({ id, which, index }) => ({
        url: `/panorama/${id}/deleteHP`,
        method: "DELETE",
        body: { which, index },
      }),
    }),
    deleteInfospot: builder.mutation({
      query: ({ id, which, index }) => ({
        url: `/panorama/${id}/deleteIS`,
        method: "DELETE",
        body: { which, index },
      }),
    }),
    addHotspot: builder.mutation({
      query: ({ id, imageKey, newPanoObject }) => ({
        url: `/panorama/${id}/addHP`,
        method: "POST",
        body: { imageKey, newPanoObject },
      }),
    }),
    addInfospot: builder.mutation({
      query: ({ id, imageKey, newPanoObject }) => ({
        url: `/panorama/${id}/addIS`,
        method: "POST",
        body: { imageKey, newPanoObject },
      }),
    }),
  }),
});

export const {
  useDeleteHotspotMutation,
  useDeleteInfospotMutation,
  useAddHotspotMutation,
  useAddInfospotMutation,
} = panoramaApi;
