import React from "react";
import Policy from "../Policies";
import privacyPolicyData from "./PrivacyPolicyData.json";
import SEO from '../../SEO';

const PrivacyPolicy = () => {
  return (
 <>
    <SEO
        title="Privacy Policy - Panostack"
        description="Read our current Privacy Policy."
        keywords="create a tour, virtual tours, virtual reality, 360, photography"
        imageUrl="../../images/panostacklogo.png"
      />
      <Policy data={privacyPolicyData} />;
      </>
  ) 
};

export default PrivacyPolicy;
