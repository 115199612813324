import * as Yup from "yup";

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .min(8, "Password should be at least 8 characters in length"),
});

export default validationSchema;
