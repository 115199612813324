import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const postsApi = createApi({
  reducerPath: "postsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile")).token
        : null;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPost: builder.query({
      query: (id) => `/posts/${id}`,
      providesTags: (result, error, id) => [{ type: "Post", id: "LIST" }],
    }),
    getPosts: builder.query({
      query: (page) => `/posts?page=${page}`,
      providesTags: [{ type: "Post", id: "LIST" }],
    }),
    getPostsByCreator: builder.query({
      query: (name) => `/posts/creator?name=${name}`,
      transformResponse: (response) => response.data,
    }),
    getMyPosts: builder.query({
      query: (creator) => `/posts/my-posts?creator=${creator}`,
      providesTags: [{ type: "Post", id: "LIST" }],
    }),
    getPostsBySearch: builder.query({
      query: (searchQuery) =>
        `/posts/search?searchQuery=${searchQuery.search || "none"}&tags=${
          searchQuery.tags
        }&type=${searchQuery.type || ""}`,
    }),
    createPost: builder.mutation({
      query: (newPost) => ({
        url: "/posts",
        method: "POST",
        body: newPost,
      }),
      invalidatesTags: [{ type: "Post", id: "LIST" }],
    }),
    comment: builder.mutation({
      query: ({ value, id }) => ({
        url: `/posts/${id}/commentPost`,
        method: "POST",
        body: { value },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Post", id: "LIST" },
      ],
    }),
    updatePost: builder.mutation({
      query: ({ id, updatedPost }) => ({
        url: `/posts/${id}`,
        method: "PATCH",
        body: updatedPost,
      }),
      invalidatesTags: [{ type: "Post", id: "LIST" }],
    }),
    deletePost: builder.mutation({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Post", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPostQuery,
  useGetPostsQuery,
  useGetPostsByCreatorQuery,
  useGetPostsBySearchQuery,
  useGetMyPostsQuery,
  useCreatePostMutation,
  useCommentMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
} = postsApi;
