import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSignInMutation, useSignUpMutation } from "../../../api";
import SignUpForm from "../../../components/Auth/SignUp/SignUpForm";
import toast from "react-hot-toast";
import SEO from '../../SEO';

const initialState = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUp = () => {
  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const [
    signIn,
    {
      isLoading: isSigningIn,
      error: signInError,
      isSuccess: signInSuccess,
      data: signInData,
    },
  ] = useSignInMutation();
  const [
    signUp,
    {
      isLoading: isSigningUp,
      error: signUpError,
      isSuccess: signUpSuccess,
      data: signUpData,
    },
  ] = useSignUpMutation();

  const comparePassword = () => {
    return form.password === form.confirmPassword;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignup) {
      if (comparePassword()) {
        if (form.password.length < 8) {
          toast.error("Password should be at least 8 characters");
          return;
        }

        try {
          const signUpResult = await signUp(form).unwrap();
          toast.success("Your profile was created successfully");
          history.push("/posts");
        } catch (error) {
          toast.error(error.data?.message || "Failed to sign up.");
        }
      } else {
        toast.error("Your passwords do not match");
      }
    } else {
      try {
        const signInResult = await signIn(form).unwrap();
        toast.success("You have signed in successfully");
        history.push("/posts");
      } catch (error) {
        toast.error(error.data?.message || "Failed to sign in.");
      }
    }
  };

  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  return (
    <>
        <SEO
        title={isSignup ? "Create an Account - Panostack" : "Sign in to your Account - Panostack"}
        description="Create a new Panostack account or Sign in to your existing one."
        keywords="sign up, sign in, account, create"
        imageUrl="../../images/panostacklogo.png"
      />
    <SignUpForm
      form={form}
      isSignup={isSignup}
      showPassword={showPassword}
      switchMode={switchMode}
      setShowPassword={setShowPassword}
      setForm={setForm}
      handleSubmit={handleSubmit}
      comparePassword={comparePassword}
      handleShowPassword={handleShowPassword}
    />
    </>
  );
};

export default SignUp;
