import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Container, Box } from "@mui/material";
import PostDetails from "./views/PostDetails";
import TourShowcase from "./views/TourShowcase";
import Navbar from "./components/Navbar";
import Home from "./views/Home/";
import PrivacyPolicy from "./views/Policies/PrivacyPolicy";
import TermsAndConditions from "./views/Policies/TermsAndConditions";
import CookiePolicy from "./views/Policies/Cookies";
import SignUp from "./views/Auth/SignUp";
import Forgot from "./views/Auth/Forgot";
import Reset from "./views/Auth/Reset";
import AboutUs from "./views/AboutUs";
import CreatorOrTag from "./components/CreatorOrTag/";
import MyProfile from "./views/MyProfile/";
import Blogs from "./views/Blogs/index.js";
import BlogDetails from "./views/BlogDetails/index.js";
import Settings from "./views/Settings";
import Landing from "./views/Landing/Landing.js";
import Footer from "./components/Footer/";
import ScrollToTop from "./ScrollToTop";
import { Toaster } from 'react-hot-toast';

const App = () => {
  const user = JSON.parse(localStorage.getItem("profile"));

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box flexGrow={1}>
      <Switch>
        {/* Separate Route for TourShowcase without Navbar or Footer */}
        <Route path="/posts/showcase/:id" exact component={TourShowcase} />

        {/* Main Layout with Navbar and Footer */}
        <Route>
          <Navbar />
          <Switch>
            <Route path="/" exact component={Landing} />
            <Container maxWidth="xl" className="containerPadding">
              <Switch>
                <Route path="/posts" exact component={Home} />
                <Route path="/posts/search" exact component={Home} />
                <Route path="/posts/:id" exact component={PostDetails} />
                <Route path={["/creator/:name", "/tags/:name"]} component={CreatorOrTag} />
                <Route path={"/profile/:creator"} exact component={MyProfile} />
                <Route path={"/creator/:id"} exact component={CreatorOrTag} />
                <Route path="/auth" exact component={() => (!user ? <SignUp /> : <Redirect to="/posts" />)} />
                <Route path="/auth/forgot" exact component={Forgot} />
                <Route path="/auth/reset/:token" component={Reset} />
                <Route path={"/settings/:id"} component={Settings} />
                <Route path="/about-us" exact component={AboutUs} />
                <Route path="/blogs" exact component={Blogs} />
                <Route path="/blogs/:slug" component={BlogDetails} />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                <Route path="/terms-conditions" exact component={TermsAndConditions} />
                <Route path="/cookie-policy" exact component={CookiePolicy} />
              </Switch>
            </Container>
          </Switch>
        </Route>
      </Switch>
      </Box>
        <Footer />
      </Box>
      <Toaster/>
    </BrowserRouter>
  );
};

export default App;
