import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetPostQuery, useGetPostsBySearchQuery } from "../../api";
import TourShowcaseContent from "../../components/TourShowcase/TourShowcaseContent";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const TourShowcase = () => {
  var defaultTour = [
    {
      name: [],
      infospotInfo: [],
      color: "lightpink",
      position: [],
      infospot: [],
      panoName: "1.JPG",
      randPanoName: "akdjvnshfirlajsilfrd-1.JPG",
      url: "/1.JPG",
      link: [],
    },
  ];
  const { id } = useParams();

  const {
    data: post,
    isLoading: postLoading,
    isError: postError,
  } = useGetPostQuery(id);

  const [store, setStore] = useState(defaultTour);

  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  useEffect(() => {
    if (post) {
      const clonedStore = deepClone(post.store);
      setStore(clonedStore);
    }
  }, [post]);

  if (!post) return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );

  return (
    <div>
      <TourShowcaseContent
        post={post}
        store={store}
        setStore={setStore}
        id={id}
      />
    </div>
  );
};

export default TourShowcase;
