import React, { useState, useRef } from "react";
import { Typography, TextField, Button, Divider } from "@mui/material";
import { useCommentMutation, useGetPostQuery } from "../../api";

const CommentSection = ({ postId }) => {
  const { data: post, isFetching } = useGetPostQuery(postId);
  const user = JSON.parse(localStorage.getItem("profile"));
  const [comment, setComment] = useState("");
  const commentsRef = useRef();
  const [addComment] = useCommentMutation();

  const handleComment = async () => {
    if (comment.length === 0) return;
    const finalComment = `${user?.result?.name}: ${comment}`;

    try {
      await addComment({ value: finalComment, id: postId }).unwrap();
      setComment("");
      if (commentsRef.current) {
        commentsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  if (isFetching) return <div>Loading comments...</div>;

  return (
    <div>
      <div>
        <div>
          <Typography gutterBottom variant="h6">
            Comments
          </Typography>
          {post?.comments?.map((c, i) => (
            <Typography key={i} gutterBottom variant="subtitle1">
              <strong>{c.split(": ")[0]}</strong>
              {c.split(":")[1]}
            </Typography>
          ))}
          <div ref={commentsRef} />
        </div>
        <Divider style={{ marginTop: "20px" }} />
        <div style={{ width: "100%" }}>
          <Typography gutterBottom variant="h6">
            Write a comment
          </Typography>
          <TextField
            disabled={!user?.result}
            fullWidth
            rows={4}
            variant="outlined"
            label="Comment"
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <br />
          <Button
            disabled={!user?.result || !comment.length}
            style={{ marginTop: "10px" }}
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleComment}
          >
            Comment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
