import React from "react";
import Policy from "../Policies";
import cookiePolicyData from "./cookiePolicyData.json";
import SEO from '../../SEO';

const CookiePolicy = () => {
  return (  
<>
<SEO
        title="Cookie Policy - Panostack"
        description="Read our current Cookie Policy."
        keywords="create a tour, virtual tours, virtual reality, 360, photography"
        imageUrl="../../images/panostacklogo.png"
      />
    <Policy data={cookiePolicyData} />;
    </>
  )
  

};

export default CookiePolicy;
