import React, { useState, useRef, useEffect } from "react";
import ImgSlideShow from "../Panorama/ImgSlideShow";
import Panorama from "../Panorama/Panorama";
import PanoramicVideo from "../Panorama/PanoramicVideo";
import { useUpdatePostMutation } from "../../api";
import ViewControls from "../PostDetails/ViewControls";

const TourShowcaseContent = ({ post, store, setStore, id }) => {
  const [storeChangesLog, setStoreChangesLog] = useState([]);
  const [which, setWhich] = useState(0);
  const [displaySpots, setDisplaySpots] = useState(true);
  const panoramaRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("profile"));
  const [updatePost, { isLoading: isUpdating, error: updateError }] =
    useUpdatePostMutation();

  const [panoState, setPanoState] = useState({
    which: 0,
    displaySpots: true,
    storeChangesLog: [],
  });

  useEffect(() => {
    const element = panoramaRef.current;
    if (element) {
      element.style.width = "100vw";
      element.style.height = "100vh";
      element.style.position = "fixed";
      element.style.top = "0";
      element.style.left = "0";
      element.style.overflow = "hidden";
    }
  }, []);

  return (
    <div>
      <div>
        <div ref={panoramaRef}>
          <ViewControls
            panoramaRef={panoramaRef}
            post={post}
            displaySpots={displaySpots}
            setDisplaySpots={setDisplaySpots}
            showFullscreenButton={true}
          />
          <Panorama
            rotate={!displaySpots}
            store={store}
            setStore={setStore}
            post={post}
            id={id}
            storeChangesLog={storeChangesLog}
            setStoreChangesLog={setStoreChangesLog}
            which={which}
            setWhich={setWhich}
            displaySpots={displaySpots}
            className="button_style"
          />
          <div>
            <ImgSlideShow store={store} setWhich={setWhich} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourShowcaseContent;
