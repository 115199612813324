import React from "react";
import Container from "../../components/Landing/Container";
import Hero from "../../components/Landing/Hero";
import Features from "../../components/Landing/Features";
import Panorama from "../../components/Panorama/Panorama.js";
import yourImage from "../../images/4.JPG";
import featuresData from "./features.json"; // Renamed to avoid naming conflict
import "./Landing.css";
import FAQs from "../../components/Landing/FAQs";
import StatisticsSection from "../../components/Landing/Statistics";

const Landing = () => {
  return (
    <body className="no-padding-top">
      <Container>
        <div name="home" className="home-wrapper">
          <Hero />
          <Panorama
            rotate={true}
            store={[
              {
                name: [],
                color: "lightpink",
                position: [],
                panoName: "3.JPG",
                randPanoName: "akdjvnshfirlajsilfrd-1.JPG",
                url: yourImage,
                link: [],
              },
            ]}
            which={0}
          />
        </div>
        <StatisticsSection />
        <Features features={featuresData.features} />
        <FAQs />
      </Container>
    </body>
  );
};

export default Landing;
