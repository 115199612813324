import React from "react";
import TextField from '@mui/material/TextField';
import textFieldsData from "./TextFields.json";

const FormFields = ({ handleInputChange, formData, placeholders }) => {
  return (
    <>
      {textFieldsData.map((textField) => {
        const placeholderValue = placeholders[textField.name];

        return (
          <TextField
            key={textField.id}
            variant="outlined"
            margin="normal"
            fullWidth
            id={textField.id}
            label={textField.label}
            name={textField.name}
            autoComplete={textField.autoComplete}
            value={formData[textField.name]}
            onChange={handleInputChange}
            placeholder={placeholderValue}
          />
        );
      })}
    </>
  );
};

export default FormFields;
