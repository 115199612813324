import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const uploadApi = createApi({
  reducerPath: "uploadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile")).token
        : null;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadToCloudinary: builder.mutation({
      query: ({ id, newPanoObject }) => ({
        url: `/upload/${id}/uploadToCloudinary`,
        method: "POST",
        body: { newPanoObject },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Post", id: "LIST" },
      ],
    }),
  }),
});

export const { useUploadToCloudinaryMutation } = uploadApi;
