import React from "react";
import { Link } from "react-router-dom";
import { Typography, Divider, Box } from "@mui/material";
import moment from "moment";

const PostHeader = ({ post }) => {
  return (
    <>
      <Box mb={3}>
        <Typography
          variant="h3"
          component="h2"
          sx={{ wordWrap: "break-word", textAlign: "center" }}
        >
          {post.title}
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
      <Typography variant="h6" gutterBottom>
        Created by:
        <Link
          to={`/creator/${post.name}`}
          style={{ textDecoration: "none", color: "#3f51b5" }}
        >
          {` ${post.name}`}
        </Link>
      </Typography>

      <Typography variant="h6" color="textSecondary" gutterBottom>
        {post.tags.map((tag, index) => (
          <Link
            key={index}
            to={`/tags/${tag}`}
            style={{ textDecoration: "none", color: "#3f51b5" }}
          >
            {` #${tag} `}
          </Link>
        ))}
      </Typography>

      <Box mb={3}>
        <Typography
          variant="body1"
          sx={{ wordWrap: "break-word" }}
          gutterBottom
        >
          {post.message}
        </Typography>
        <Typography noWrap variant="body1">
          {post.price && `Price: $${post.price}`}
        </Typography>
        <Typography variant="caption">
          {moment(post.createdAt).format("llll")}
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
    </>
  );
};

export default PostHeader;
